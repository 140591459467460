import { format } from 'date-fns';
import { generateHash } from "../adobe/adobe"


function addTracking(obj) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
}
let mediaTracking = {
    search_hotel: (selectedDest, rooms, checkin, checkout) => {
        let obj = {
            event: "search_hotels",
            language: "en",
            lob: "Hotel",
            searchCity: selectedDest.city_name || "",
            searchCountry: selectedDest.country_name,
            checkinDate: format(checkin, 'dd-MM-yyyy'),
            checkoutDate: format(checkout, 'dd-MM-yyyy'),
            roomcount: rooms.rooms.length,
            adultPax: rooms.adults,
            childPax: rooms.childrens
        }
        addTracking(obj)
    },
    view_hotel: (data, urlData) => {
        let obj = {
            event: "view_hotel",
            language: "en",
            lob: "Hotel",
            adultPax: urlData.adultCount,
            childPax: urlData.childrenCount,
            hotelID: data.destination_id,
            HotelRating: data.star_rating,
            HotelRoomname: data.room_details.name,
            Hotelname: data.hotel_name,
            checkinDate: format(new Date(urlData?.checkin), 'yyyy-MM-dd'),
            checkoutDate: format(new Date(urlData?.checkout), 'yyyy-MM-dd'),
            policy: "",
            roomcount: urlData.rooms.length,
            searchCity: data.city,
            searchCountry: data.country,
            searchKeyword: urlData.region
        }
        addTracking(obj)
    },
    room_selection: (roomData, urlData) => {
        let policy = ""
        if (roomData?.option_detail?.length)
            policy = roomData.option_detail.map(opt => opt.text).join(" | ")

        let Obj = {
            event: "room_selection",
            language: "en",
            lob: "Hotel",
            paymentAmount: roomData.price.base_price,
            CurrencyCode: roomData.price.currency_code,
            adultPax: urlData.adultCount,
            childPax: urlData.childrenCount,
            hotelID: urlData.vid,
            HotelRating: roomData.roomSelectionData.reviewAndRatings.star_rating,
            policy: policy,
            HotelRoomname: roomData.roomName,
            Hotelname: roomData.roomSelectionData.name,
            checkinDate: format(new Date(urlData?.checkin), 'yyyy-MM-dd'),
            checkoutDate: format(new Date(urlData?.checkout), 'yyyy-MM-dd'),
            roomcount: urlData.rooms.length,
            searchCity: roomData.roomSelectionData.city_name,
            searchCountry: roomData.roomSelectionData.country,
            searchKeyword: roomData.roomSelectionData.name,
        }
        addTracking(Obj)
    },
    proceed_to_payment: (data) => {
        let policy = "";
        if (data?.hotel_details?.option_details)
            policy = data.hotel_details.option_details.map(option => option.text).join("|")
        let Obj = {
            event: "proceed_to_payment",
            language: "en",
            lob: "Hotel",
            paymentAmount: data.price_summary.total.value,
            currencyCode: data.price_summary.total.currency_code,
            adultPax: data.booking_details.guests.adult,
            childPax: data.booking_details.guests.children,
            hotelID: data.hotel_details.vid,
            HotelRating: data.hotel_details.reviewAndRatings.star_rating,
            HotelRoomname: data.booking_details.room_name,
            Hotelname: data.hotel_details.name,
            checkinDate: data.booking_details.checkin_date,
            checkoutDate: data.booking_details.checkout_date,
            policy: policy,
            roomcount: data.booking_details.no_of_room,
            searchCity: data.hotel_details.city_name,
            searchCountry: data.hotel_details.country,
            searchKeyword: data.hotel_details.name,
        }
        addTracking(Obj)
    },
    itinerary_hotel: (data, customerInfo) => {
        try {
            let policy = ""
            if (data?.data?.hotel_details?.option_detail?.length)
                policy = data.data.hotel_details.option_detail.map(opt => opt.text).join(" | ")
            let commonDetail = JSON.parse(sessionStorage.getItem("tracking_details")) || {}
            let customerData = {
                customerFirstName: generateHash(customerInfo?.first_name),
                customerLastName: generateHash(customerInfo?.last_name),
                customerEmail: generateHash(customerInfo?.email),
                customerPhone: generateHash(customerInfo?.mobile_no),
                customerTitle: generateHash(customerInfo?.salutation),
                customerId: customerInfo?.customer_id ? generateHash(customerInfo?.customer_id) : ""
            }
            let Obj = {
                event: "itinerary_hotel",
                language: "en",
                lob: "Hotel",
                paymentAmount: data?.data?.totalSummary?.[0].value,
                currencyCode: data?.data?.totalSummary?.[0].currency_code,
                paymentMethod: data?.data?.booking_details?.payment_method,
                transactionID: data.data.booking_details?.transaction_id,
                bookingID: data.data.booking_details.booking_id,
                bookingstatus: data.data.booking_details.booking_status_label,
                adultPax: data.data.booking_details.pax.adults,
                childPax: data.data.booking_details.pax.childs,
                hotelID: data.data.hotel_details.vid,
                HotelRating: data.data.hotel_details.star_rating,
                HotelRoomname: data.data.booking_details.room_name,
                Hotelname: data.data.hotel_details.name,
                checkinDate: data.data.booking_details.checkin_date,
                checkoutDate: data.data.booking_details.checkout_date,
                policy: policy,
                roomcount: data.data.booking_details.pax.rooms,
                searchCity: data.data.hotel_details.city_name,
                searchCountry: data.data.hotel_details.country_name,
                searchKeyword: data.data.hotel_details.name,
                ...customerData
            }
            addTracking(Obj)
        } catch (error) {
            console.log("error", error)
        }
    }
}

module.exports = mediaTracking

